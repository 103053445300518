import React from 'react'

export const SucessSigningMessage = () => {
  return (
    <div
    style={{
      padding: 10,
      textAlign: 'center',
      display: 'table-cell',
      wordWrap: 'break-word',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'baseline',
      fontWeight: '700',
    }}>
      <h1>Santé+ vous remercie, vous pouvez désormais fermer cette fenêtre.</h1>
    </div>
  )
}