import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const image = require('../Images/SanteSO.jpg')

const Toolbari = () => {
    return (
      <AppBar position="static" style={{backgroundColor : 'white'}} >
        <Toolbar style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <img
                style={{maxHeight: 300 , maxWidth : 300}}
                src={image}
                loading="lazy" 
              />
        </Toolbar>
      </AppBar>
    );
}

export default Toolbari;
