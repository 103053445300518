import React, {useState} from 'react'

import Stack from '@mui/material/Stack'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5'

import Fab from '@mui/material/Fab'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'

import FormControl from '@mui/material/FormControl'
import FilledInput from '@mui/material/FilledInput'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'

import RotateRightIcon from '@mui/icons-material/RotateRight'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'

import './styles/PdfViewer.css'

const PdfReact = props => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageScale, setPageScale] = useState(125)
  const [pageRotate, setPageRotate] = useState(0)

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages)
  }

  const handleChange = prop => event => {
    setPageScale(event.target.value)
  }

  function HandlePageRotate(right) {
    var newDeg = pageRotate

    if (newDeg >= 360) newDeg = 0
    else if (newDeg < 0) newDeg = 270

    if (right) newDeg = newDeg + 90
    else newDeg = newDeg - 90

    if (newDeg >= 360) newDeg = 0
    if (newDeg < 0) newDeg = 270

    setPageRotate(newDeg)
  }

  return (
    <div
      style={{
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}>
        {/* Scale */}
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Fab
            size="small"
            color="primary"
            aria-label="Next"
            onClick={() => setPageScale(pageScale - 25)}
            disabled={pageScale <= 25}>
            <ZoomOutIcon />
          </Fab>

          <FormControl sx={{width: '35%'}} variant="outlined">
            <FilledInput
              inputProps={{
                style: {
                  padding: 5,
                },
              }}
              id="outlined-adornment-weight"
              size="small"
              value={pageScale}
              onChange={handleChange('pageScale')}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-pageScale-helper-text"
            />
            <FormHelperText id="outlined-pageScale-helper-text">
              Zoom
            </FormHelperText>
          </FormControl>

          <Fab
            size="small"
            color="primary"
            aria-label="Next"
            onClick={() => setPageScale(pageScale + 25)}
            disabled={pageScale >= 200}>
            <ZoomInIcon />
          </Fab>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          margin={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Fab
            size="small"
            color="primary"
            aria-label="Next"
            onClick={() => HandlePageRotate(false)}>
            <RotateLeftIcon />
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="Next"
            onClick={() => HandlePageRotate(true)}>
            <RotateRightIcon />
          </Fab>
        </Stack>
      </div>

      <div id="pdf-container">
        <Document
          file={props.pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-document"
          rotate={pageRotate}>
          <Page
            pageNumber={pageNumber}
            scale={pageScale / 100}
            className="pdf-page first-pdf-page"
          />
        </Document>
      </div>

      <Stack
        direction="row"
        spacing={2}
        margin={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Fab
          size="small"
          color="primary"
          aria-label="Next"
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber <= 1}>
          <ArrowCircleLeftIcon />
        </Fab>

        <p>
          {pageNumber} / {numPages}
        </p>

        <Fab
          size="small"
          color="primary"
          aria-label="Next"
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber >= numPages}>
          <ArrowCircleRightIcon />
        </Fab>
      </Stack>
    </div>
  )
}

export default PdfReact