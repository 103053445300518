import React, { useState, useEffect } from 'react';
import FreeHandSign from './FreeHandSign';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@material-ui/core';

import Dialog from '@mui/material/Dialog';
import { useHistory } from "react-router-dom";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { InputType, SignatureRequest, SpSignatureClient } from '../services/ApiClient';


import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import { PopupDialog } from "../components/PopupDialog"

const successImage = require('../Images/Success.png')
const failedImage = require('../Images/Failed.png')


const theme = createTheme({
    palette: {
        primary: {
            main: '#003172'
        },
        secondary: {
            main: '#D45041'
        }
    }
});


const Formulaire = (props) => {
    const [Checked, setChecked] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    //Expiration
    const [DateLimit, setDate] = useState(undefined);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    //
    const [callBackUrl, setCallBackUrl] = useState(undefined);
    //Handle Dialog Type
    const [error, setError] = React.useState(false);


    const ApiClient = new SpSignatureClient((window as any).SPSignatureAPI);
    const history = useHistory();

    const redirect = () => {
        if(!!callBackUrl) window.location.replace(callBackUrl);        
        else history.push('/Success');
    };
    //InputList
    const [inputList, setInputList] = React.useState(props.dtFromParent.UserData.formulaire.inputs);
    const [inputListSelected, setInputListSelected] = React.useState([]);
    const [requiredInput, setRequiredInput] = React.useState([]);

    useEffect(() => {
        setDate(props.dtFromParent.UserData.dateLimite);
        if (DateLimit != null) {
            var date = new Date(DateLimit);
            if (date.getTime() > Date.now())
                setIsExpired(true);
        }
        //setInputList(props.dtFromParent.UserData.formulaire.inputs);

        if (inputList != null) {
            if (inputList.length > 0) {
                inputList.forEach((e, i) => {
                    var toAdd = true;
                    var ArrayHisto = inputListSelected;
                    var reqInput = requiredInput;
                    inputListSelected.forEach((k, v) => {
                        if (k.key == e.nomInterne)
                            toAdd = false;
                    });
                    if (toAdd) {
                        var isAddedTolist = false;
                        if (e.keyValues != null && e.keyValues != undefined)
                            if (e.keyValues.length < 2 && (e.inputType == undefined || e.inputType == InputType.Select) && e.obligatoire == true) {
                                ArrayHisto.push({ key: e.nomInterne, value: [e.keyValues[0].key] });
                                reqInput.push({ index: i, key: e.nomInterne, hasValue: true });
                                isAddedTolist = true;

                                const updateList = [...inputList];

                                var updateItem = updateList[i];
                                updateItem.hide = true;

                                updateList[i] = { ...updateList[i] };
                                updateList[i] = updateItem;
                                setInputList(updateList);
                            }

                        if (!isAddedTolist) {
                            ArrayHisto.push({ key: e.nomInterne, value: [] });
                            if (e.obligatoire)
                                reqInput.push({ index: i, key: e.nomInterne, hasValue: false });
                        }

                    }
                    setInputListSelected(ArrayHisto);
                    setRequiredInput(reqInput);
                });

            }

        }
    }, []);

    const OnSubmit = (e) => {

        setOpenBackdrop(true);


        e.preventDefault();
        props.dtFromParent.data.substr(22)

        const dictionaryInputListSelected = Object.create(null);

        inputListSelected.forEach((e, i) => {
            dictionaryInputListSelected[e.key] = e.value;
        });

        let callbackUrl = '';

        for (const input of props.dtFromParent.UserData.formulaire.inputs) {
            const selectedInputValue = dictionaryInputListSelected[input.nomInterne];
            if (selectedInputValue && input.keyValues) {
                const keyValue = input.keyValues.find(kv => kv.key === selectedInputValue[0]);
                if (keyValue && keyValue.callbackUrl) {
                    callbackUrl = keyValue.callbackUrl;
                    break;
                }
            }
        }

        setCallBackUrl(callbackUrl)
        ApiClient.signature_ValidateSignature(
            {
                ...props.dtFromParent.UserData,
                signatureImage: props.dtFromParent.data.substr(22),
                formulaireDataResult: dictionaryInputListSelected
            }).then(x => {
                setOpenBackdrop(false);
                setError(false);
                setOpenDialog(true);
            }).catch(err => {
                setOpenBackdrop(false);
                setError(true);
                setOpenDialog(true);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(true);
    };

    const handleEntreTag = (e, value, index) => {

        e.preventDefault();
        const list = [...inputListSelected];
        if (inputList[index].multiSelection) {
            list[index].value = value.map(kv => kv.key);

            if (inputList[index].obligatoire) {
                const reqInputs = [...requiredInput];
                var i = reqInputs
                    .findIndex(e =>
                        e.index == index &&
                        e.key == inputList[index].nomInterne);

                var reqInput = reqInputs[i];
                if (value == null) reqInput.hasValue = false;
                else value.length < 1 ? reqInput.hasValue = false : reqInput.hasValue = true;

                reqInputs[i] = { ...reqInputs[i] };
                reqInputs[i] = reqInput;
                setRequiredInput(reqInputs);


            }

        } else {
            list[index].value = [];
            if (value != null)
                list[index].value.push(value.key);

            if (inputList[index].obligatoire) {
                const reqInputs = [...requiredInput];
                var i = reqInputs
                    .findIndex(e =>
                        e.index == index &&
                        e.key == inputList[index].nomInterne);

                var reqInput = reqInputs[i];
                value == null ? reqInput.hasValue = false : reqInput.hasValue = true;

                reqInputs[i] = { ...reqInputs[i] };
                reqInputs[i] = reqInput;
                setRequiredInput(reqInputs);
            }

        }

        console.log(inputList);
        console.log(list);
        console.log(requiredInput);

        setInputListSelected(list);
    };

    const handleTextField = (e, index) => {
        e.preventDefault();
        const textFieldValue = e.target.value;
        const list = [...inputListSelected];

        list[index].value = [];
        if (textFieldValue != null)
            list[index].value.push(textFieldValue);

        if (inputList[index].obligatoire) {
            const reqInputs = [...requiredInput];
            var i = reqInputs
                .findIndex(e =>
                    e.index == index &&
                    e.key == inputList[index].nomInterne);

            var reqInput = reqInputs[i];
            textFieldValue == null ? reqInput.hasValue = false : reqInput.hasValue = true;

            reqInputs[i] = { ...reqInputs[i] };
            reqInputs[i] = reqInput;
            setRequiredInput(reqInputs);

        }




        setInputListSelected(list);
    };

    const handleCheckbox = (e, index) => {
        const value = e.target.value;
        e.preventDefault();
        const list = [...inputListSelected];
        if (list[index].value == undefined || list[index].value == null) list[index].value = [];

        if (e.target.checked) {
            list[index].value.push(value);
        } else {
            list[index].value = list[index].value.filter(item => item != value);
        }


        if (inputList[index].obligatoire) {
            const reqInputs = [...requiredInput];
            var i = reqInputs
                .findIndex(e =>
                    e.index == index &&
                    e.key == inputList[index].nomInterne);

            var reqInput = reqInputs[i];
            reqInput.hasValue = list[index].value != undefined && list[index].value != null && list[index].value.length > 0;

            reqInputs[i] = { ...reqInputs[i] };
            reqInputs[i] = reqInput;
            setRequiredInput(reqInputs);
        }

        setInputListSelected(list);

    };

    const handleRadioGroup = (e, index) => {
        const value = e.target.value;
        e.preventDefault();
        const list = [...inputListSelected];

        list[index].value = [];

        if (e.target.checked)
            if (value != null)
                list[index].value.push(value);


        if (inputList[index].obligatoire) {
            const reqInputs = [...requiredInput];
            var i = reqInputs
                .findIndex(e =>
                    e.index == index &&
                    e.key == inputList[index].nomInterne);

            var reqInput = reqInputs[i];
            reqInput.hasValue = list[index].value != undefined && list[index].value != null && list[index].value.length > 0

            reqInputs[i] = { ...reqInputs[i] };
            reqInputs[i] = reqInput;
            setRequiredInput(reqInputs);
        }




        setInputListSelected(list);

    };

    const handleBackdropClick = (event) => {
        setOpenDialog(true);
    };

    return (
        <form
            onSubmit={OnSubmit}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <List style={{ maxHeight: 300, minWidth: '350px', width: '50%', maxWidth: '80%', overflow: 'auto' }}>
                {

                    inputList.map((o, i) => {
                        if (o.hide == null || o.hide == undefined || o.hide == false)
                            switch (o.inputType) {
                                case InputType.Textbox:
                                    return (
                                        <ListItem
                                            key={i}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                marginBottom: '10px'
                                            }}>
                                            <TextField
                                                id={o.NomInterne + i}
                                                type="text"
                                                onChange={(e) => handleTextField(e, i)}
                                                label={o.libelle}
                                                placeholder={o.nomInterne}
                                                fullWidth
                                            />
                                        </ListItem>
                                    );
                                case InputType.Checkbox:
                                    return (
                                        <ListItem key={i}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                marginBottom: '10px'
                                            }}>

                                            <FormControl>
                                                <FormLabel id="controlled-checkbox-group">{o.libelle + (o.obligatoire ? "*" : "")}</FormLabel>
                                                <FormGroup>
                                                    {
                                                        o.keyValues.map((kv, kvi) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={o.NomInterne + "-" + kvi}
                                                                    style={{ margin: 5 }}
                                                                    control=
                                                                    {
                                                                        <Checkbox key={"Checkbox" + o.NomInterne + "-" + kvi} value={kv.key} onChange={(e) => handleCheckbox(e, i)} />
                                                                    }
                                                                    label={kv.value}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </FormGroup>
                                            </FormControl>
                                        </ListItem>
                                    );
                                case InputType.Radio:
                                    return (
                                        <ListItem key={i}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                marginBottom: '10px'
                                            }}>
                                            <FormControl>
                                                <FormLabel id="demo-controlled-radio-buttons-group">{o.libelle + (o.obligatoire ? "*" : "")}</FormLabel>
                                                <RadioGroup
                                                    key={"FormControlLabel" + i}
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    onChange={(e) => handleRadioGroup(e, i)}
                                                >
                                                    {
                                                        o.keyValues.map((kv, kvi) => {
                                                            return (
                                                                <FormControlLabel
                                                                    id={o.NomInterne + "-" + kvi}
                                                                    style={{ margin: 5 }}
                                                                    control={<Radio />}
                                                                    value={kv.key}
                                                                    label={kv.value}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                        </ListItem>
                                    );
                                case InputType.Select:
                                    return (
                                        <ListItem key={i}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                marginBottom: '10px'
                                            }}>
                                            <Autocomplete
                                                sx={{ width: '100%' }}
                                                multiple={o.multiSelection}
                                                id={o.NomInterne}
                                                options={o.keyValues}
                                                getOptionLabel={option => (option as any).value}
                                                onChange={(e, value) => handleEntreTag(e, value, i)}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={o.libelle + (o.obligatoire ? "*" : "")}
                                                        placeholder={o.nomInterne}
                                                    />
                                                )}
                                            />
                                        </ListItem>
                                    )
                                default:
                                    return (
                                        <ListItem key={i}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row',
                                                marginBottom: '10px'
                                            }}>
                                            <Autocomplete
                                                sx={{ width: '100%' }}
                                                multiple={o.multiSelection}
                                                id={o.NomInterne}
                                                options={o.keyValues}
                                                getOptionLabel={option => (option as any).value}
                                                onChange={(e, value) => handleEntreTag(e, value, i)}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={o.libelle + (o.obligatoire ? "*" : "")}
                                                        placeholder={o.nomInterne}
                                                    />
                                                )}
                                            />
                                        </ListItem>
                                    )
                            }
                    })
                }
            </List>

            <ThemeProvider theme={theme}>
                <FormControlLabel
                    style={{ margin: 5 }}
                    control={
                        <Checkbox
                            color="secondary"
                            checked={Checked}
                            onChange={e => setChecked(e.target.checked)}
                            disabled={
                                props.dtFromParent.data == '' ||
                                isExpired ||
                                !requiredInput.map(r => r.hasValue).reduce((a, b) => a && b, true)
                            }
                        />
                    }
                    label="J’ai lu et accepte le document dans son intégralité."
                />

                <Button
                    style={{ margin: '30px' }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                        !Checked ||
                        isExpired ||
                        props.dtFromParent.data == '' ||
                        !requiredInput.map(r => r.hasValue).reduce((a, b) => a && b, true)
                    }>
                    Envoyer
                </Button>
            </ThemeProvider>

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <PopupDialog
                title={error 
                    ? "Une erreur est survenue pendant l'enregistrement" 
                    : "Enregistrement réussi"}  
                message={error 
                    ? "Une erreur est survenue pendant l'enregistrement. Veuillez réessayer ou contacter le centre de santé."
                    : "Votre demande a été bien enregistrée."
                }
                onClose={() => { 
                    setOpenDialog(v => !v) 
                    if(!error) redirect()
                }}
                open={openDialog}
                isSuccess={!error}
             />
            
        </form>
    )
}

export default Formulaire;
