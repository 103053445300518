import React ,{useState,useEffect} from 'react';
import FreeHandSign from './FreeHandSign';
import Formulaire from './formulaire';
import { useHistory, useParams } from 'react-router-dom';

import {Stack, Divider, Grid, Typography, Box,LinearProgress} from '@mui/material'

import PdfReact from './PdfReact';

import {SpSignatureClient} from '../services/ApiClient'
import {SucessSigningMessage} from './SucessSigningMessage';


const Content = (props) => {

    const [data, setData] = useState('');
    const [pdfState, setPdfState] = useState(false);
    const [UserData, setInfo] = useState(undefined);
    const { id } = useParams();
    const ApiClient = new SpSignatureClient((window as any).SPSignatureAPI);
    const [pdfUrl, setPdfUrl] = useState(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingPdf, setLoadingPDF] = useState(false);

    useEffect(() => {
        ApiClient.signature_GetSignatureById(id)
        .then(x => {
            setInfo(x);
            setPdfUrl(`data:application/pdf;base64,${x.pdFile}`);
            setLoading(false)
        }).catch(ex=>{
            console.log(ex);
            setLoading(false)
                });;

        setInterval(function() {setLoadingPDF(true);}, (window as any).LoadingPdfTimeInMs == undefined? 1000:(window as any).LoadingPdfTimeInMs);

    }, []);

    return loading ? (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
      
      ) :
    (UserData != undefined) ? (
      <Stack spacing={1} divider={<Divider orientation="horizontal" />}>
      <div
        style={{
          backgroundColor: '#EEF2F6',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <p style={{margin: '5%', textAlign: 'center'}}>
          <b
            style={{
              fontSize: 20,
              textAlign: 'center',
              display: 'table-cell',
              wordWrap: 'break-word',
              fontWeight: '700',
            }}>
            {UserData.texte}
          </b>
        </p>
      </div>

     {loadingPdf && <PdfReact pdfUrl={pdfUrl} />}

      <FreeHandSign
        getData={data => {
          setData(data)
        }}
      />
      {UserData !== undefined && (
        <div>
          <Formulaire dtFromParent={{data, pdfState, UserData}} />
        </div>
      )}
    </Stack>
  ) : (
    <SucessSigningMessage />
  )
}
export default Content;
