import React, { useState, type PropsWithChildren, useEffect } from 'react'
import { Drawer, Stack, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Button, Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as SuccessIcon } from '../Images/success.svg'
import { ReactComponent as FailureIcon } from '../Images/failure.svg'

export type PopupDialogProps = {
    onClose: () => void
    isSuccess: boolean
    title: string
    message: string
    open: boolean
}
export function PopupDialog({ onClose, isSuccess, title, message, open }: PopupDialogProps) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    return <>{isMobile
        ?
        <Drawer anchor='bottom' open={open} onClose={onClose} >
            <Stack justifyItems="center" gap="5px" margin={2}>
                <Box flexDirection="row" flex="1" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                    {isSuccess ? <SuccessIcon width="32" height="32" /> : <FailureIcon width="32" height="32" />}
                    <Typography variant="h5" fontWeight="700" >{title}</Typography>
                </Box>
                <Typography variant="body1">{message}</Typography>

                <DialogActions sx={{ flex: '1', flexDirection: 'row', justifyContent: 'end', display: 'flex' }}>
                    <Button variant='outlined' onClick={onClose}>
                        {isSuccess ? 'OK' : 'Retour'}
                    </Button>
                </DialogActions>
            </Stack>
        </Drawer>

        : <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>
                <Box flexDirection="row" flex="1" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                    {isSuccess ? <SuccessIcon width="32" height="32" /> : <FailureIcon width="32" height="32" />}
                    <Typography variant="h5" fontWeight="700" >{title}</Typography>
                </Box>            </DialogTitle>
            <DialogContent>
                <Box justifyContent="flex-start" flex="1" flexDirection="row" alignItems="center" gap="5px">
                    <Typography variant="body1">{message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ flex: '1', flexDirection: 'row', justifyContent: 'end', display: 'flex' }}>
                <Button variant='contained' onClick={onClose}>
                    {isSuccess ? 'OK' : 'Retour'}
                </Button>
            </DialogActions>
        </Dialog>}
    </>
}