import React, {useRef} from 'react'
import SignaturePad from 'react-signature-canvas'
import {IconButton, Typography, Box} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import {createTheme, ThemeProvider} from '@material-ui/core'

const theme = createTheme({
  palette: {
    primary: {
      main: '#003172',
    },
    secondary: {
      main: '#D45041',
    },
  },
})

const FreeHandSign = props => {
  let sigPad = useRef<SignaturePad>({})
  let data = ''
  const Sig = {
    height: 'auto',
    width: '245',
  }

  return (
    <ThemeProvider theme={theme}>
      <view
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
        }}>
        <Typography
          color="primary"
          style={{
            margin: '10px',
            userSelect: 'none',
            WebkitUserSelect: 'none',
          }}>
          Veuillez signez ici
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: '3px',
            paddingY: '3px',
            border: '4px solid #003172',
            borderRadius: '25px',
          }}>
          <SignaturePad
            canvasProps={Sig}
            ref={sigPad}
            penColor="black"
            onEnd={() => {
              data = sigPad.current.toDataURL()
              props.getData(data)
            }}
          />
        </Box>

        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              sigPad.current.clear()
              props.getData('')
            }}
            component="span">
            <RefreshIcon />
          </IconButton>
        </div>
      </view>
    </ThemeProvider>
  )
}

export default FreeHandSign
