import './App.css';
import React,{useState} from 'react';
import Toolbari from './components/toolbar';
import Content from './components/Content';
import {SucessSigningMessage} from './components/SucessSigningMessage';
import {BrowserRouter as Router,Route,Switch } from'react-router-dom';

function App() {
  return (
    
      <Router>
            <Toolbari  />
            <Switch>
              <Route path="/Success">
                  <SucessSigningMessage/>  
              </Route>
              <Route exact path="/:id">
                   <Content />
              </Route>
            </Switch>            
      </Router>
   
  );
}

export default App;
